import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ModuleCard from './ModuleCard'
import { useSearchParams } from 'react-router-dom'
import WithAuth from 'WithAuth'
import { reorderData } from 'functions'
import ImportContentBox from './ImportContentBox'
import EmptyState from './contents/EmptyState';
import EditModule from './contents/EditModule';
import { GetCourseEditContents } from 'api';
import { Course, Module } from 'types';
import NavBuilder from 'components/navbar/NavBuilder';
import CoursePreferences from './course-preferences/CoursePreferences';
import { PAGE, TERM } from 'consts';
import { useUserContext } from 'UserContext';
import { BuilderProvider } from './BuilderContext';
import './coursebuilder.css'


function CourseBuilder() {

    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState("Content")
    const [contentDisplay, setContentDisplay] = useState(<EmptyState />)
    const [course, setCourse] = useState<Course>(null)
    const [modules, setModules] = useState([])
    const [importTargetModule, setImportTargetModule] = useState<string | null>(null)
    const { setPage } = useUserContext()
    const courseId = searchParams.get("c")

    useEffect(() => {
        setPage(PAGE.COURSE_BUILDER)
        document.documentElement.classList.add("no-scroll")
        return () => {
            document.documentElement.classList.remove("no-scroll")
        }
    }, [setPage]);

    // module functions 
    const addModule = () => {
        setContentDisplay(
            <EditModule
                setContentDisplay={setContentDisplay}
                reload={handleGetCourseContents}
            />
        )
    }

    const handleTab = (t: string) => {
        setTab(t)
        handleGetCourseContents()
    }

    const handleGetCourseContents = async () => {
        if (!courseId) return
        const response = await GetCourseEditContents(courseId)
        // console.log(response);

        if (response.status) {
            setCourse(response.data)
            setModules(response.data.modules)
        }
        try {

            if (response.detail === "Course not found!") {
                window.location.href = "/not-found"
            }
        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        handleGetCourseContents()
    }, [])

    const handleDragEnd = (result: any) => {
        if (!courseId) return
        if (!result.destination) return; // if dropped outside the list
        if (result.destination.index === result.source.index) { return }

        const itemsCopy = Array.from(modules);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);

        reorderData(itemsCopy, "course_modules", courseId);
        setModules(itemsCopy);
    };

    const courseModuleList = modules && modules.map((item: Module, index: number) => (
        <Draggable
            draggableId={"drag-" + item.id}
            index={index}
            key={item.id}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="card-content mb-3" module-id={module.id}>
                    <ModuleCard
                        key={item.id}
                        provided={provided}
                        module={item}
                        setContentDisplay={setContentDisplay}
                        reload={handleGetCourseContents}
                        handleImport={handleImport}
                    />
                </div>
            )}
        </Draggable>
    ))


    const handleImport = (moduleId: string) => {
        console.log('moduleId', moduleId)
        setImportTargetModule(moduleId)
    }

    console.log('course', course)

    return (
        <BuilderProvider>
            <>
                {course &&
                    <NavBuilder course={course} setTab={handleTab} />
                }

                {tab == "Preferences" ?
                    <CoursePreferences
                        reload={handleGetCourseContents}
                    /> :
                    <div className="course-builder">
                        <div className="main-panel">
                            <div className="header-box">
                                <p>CONTENT</p>
                                <div className="button is-secondary is-outlined" onClick={addModule}>+ Add {TERM.MODULE}</div>
                            </div>

                            <div className="card-content-box" >
                                <DragDropContext onDragEnd={handleDragEnd} >
                                    <Droppable droppableId='card-content-list' key={"dropable-" + courseId} >
                                        {(provided) => (
                                            <div ref={provided.innerRef} >
                                                {courseModuleList}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <br />
                        </div>
                        <div className="main-body">
                            {importTargetModule && course &&
                                <ImportContentBox
                                    reload={handleGetCourseContents}
                                    courseId={course.id}
                                    moduleId={importTargetModule}
                                    close={() => setImportTargetModule(null)} />}

                            {!importTargetModule && contentDisplay}
                        </div>
                    </div>
                }
            </>

        </BuilderProvider>
    )


}


export default WithAuth(CourseBuilder)

