import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { Button, Input, Space, Dropdown, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { API } from "api";
import { Course, TagType } from "types";
import { TERM } from "consts";
import { NewCourseForm } from "./NewCourseForm";
import { AuthoredCoursesTable } from "./AuthoredCourseTable";
import { isMobile } from "react-device-detect";

const statusOptions = [
    {
        key: "All",
        label: "All",
    },
    {
        key: "Draft",
        label: "Draft",
    },
    {
        key: "Publish",
        label: "Publish",
    },
    {
        key: "Archive",
        label: "Archive",
    },

];



export default function AuthoredCourses() {
    const [courses, setCourses] = useState<Course[]>([]);
    const [originalData, setOriginalData] = useState<Course[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    const [tags, setTags] = useState<TagType[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>("All");
    const [selectedTag, setSelectedTag] = useState<string[]>([]);

    const handleGetAllAuthoredCourses = async (params = "archive=False") => {
        setLoading(true);
        console.log(params);
        const response = await API.Profile.GetAuthoredCourses(params);
        console.log(response);
        setCourses(response.data);
        setOriginalData(response.data);
        setLoading(false);
    };


    const handleGetAllTags = async () => {
        const response = await API.Tag.GET_ALL();
        console.log(response);
        if (response.status) {
            setTags(response.data);
        }
    }


    useEffect(() => {
        handleGetAllAuthoredCourses()
        handleGetAllTags();
    }, []);

    const handleSearch = (keyword: string) => {
        console.log(keyword);
        // Filter the originalData based on the title containing the keyword
        const filteredCourses = originalData.filter((course: Course) =>
            course.course_name.toLowerCase().includes(keyword.toLowerCase())
        );
        setCourses(filteredCourses);
    };

    useEffect(() => {
        const handleFilterStatus = async () => {
            if (selectedStatus == "Archive") {
                await handleGetAllAuthoredCourses("archive=True");
            } else if (selectedStatus == "All") {
                await handleGetAllAuthoredCourses("archive=False");
            } else {
                await handleGetAllAuthoredCourses(`archive=False&status=${selectedStatus}`);
            }
        }
        handleFilterStatus()
    }, [selectedStatus]);

    useEffect(() => {
        if (selectedTag.length == 0) {
            setCourses(originalData);
            return
        }
        const handleFilterTag = async () => {
            console.log('selectedTag', selectedTag)

            const filteredCourses = originalData.filter((course: Course) =>
                course.tags.some((tag: any) => selectedTag.includes(tag.id))
            );
            setCourses(filteredCourses);
        }

        handleFilterTag();
    }, [selectedTag]);


    const addNewCourse = () => {
        setShowForm(true);
    };


    return (
        <div
            className={"authored-courses "}
        >

            {showForm && (
                <NewCourseForm close={() => setShowForm(false)} />
            )}


            <div className="header-box">
                <div className="title m-0">Authored {TERM.COURSE}s</div>
                <div className="query">
                    <Input
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search"
                        style={{ width: "150px" }}
                    />

                    <Select
                        value={selectedTag}
                        style={{ width: "max-content", minWidth: "120px" }}
                        maxTagCount={3}
                        mode="multiple"
                        placeholder="Select Tags"
                        onChange={(e) => setSelectedTag(e)}
                        options={tags.map((tag) => ({ value: tag.id, label: tag.name }))}

                    />

                    <Dropdown
                        menu={{ items: statusOptions, onClick: (e) => setSelectedStatus(e.key) }}
                    >
                        <Button >
                            <Space>
                                <span style={{ opacity: .7 }}>Status :</span>
                                {selectedStatus}
                                <DownOutlined style={{ fontSize: ".7rem", opacity: .6 }} />
                            </Space>
                        </Button>
                    </Dropdown>
                </div>
                {!isMobile &&
                    <Button
                        onClick={addNewCourse}
                        type="primary"
                    >
                        + New {TERM.COURSE}
                    </Button>
                }
            </div>

            <div className="data-body">
                <AuthoredCoursesTable
                    loading={loading}
                    courses={courses}
                    reload={handleGetAllAuthoredCourses}
                />
            </div>
        </div>
    );
}


// const FilterModal = () => {

// }