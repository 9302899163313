import { notification, Popconfirm } from 'antd'
import React from 'react'
import EditLesson from './contents/EditLesson'
import EditQuiz from './contents/EditQuiz'
import EditTask from './contents/EditTask'
import EmptyState from './contents/EmptyState'
import { DeleteModuleContent, EditCourseContent } from 'api'
import { Module } from 'types'
import { useBuilder } from './BuilderContext'

export default function Content(props: {
    module: Module,
    content: any,
    setContentDisplay: React.Dispatch<React.SetStateAction<JSX.Element>>,
    reload: () => void
}) {
    const { module, content, setContentDisplay, reload } = props;
    const { setBuilderLoading } = useBuilder()
    const [api, contextHolder] = notification.useNotification()

    const handleEditContent = async () => {
        setBuilderLoading(true)
        console.log('clicked', content)
        const contentType = content.obj_type === "Quizes" ? "Quiz" : content.obj_type;
        const { status, data: contentData } = await EditCourseContent(contentType, content.obj_id)
        console.log("content-data---->", contentData);

        if (status) {
            if (contentType == "Lesson") {
                setContentDisplay(<EditLesson
                    lesson={contentData}
                    module={module}
                    setContentDisplay={setContentDisplay}
                    reload={reload}
                />)
            } else if (contentType == "Quiz") {
                setContentDisplay(<EditQuiz
                    quizId={content.obj_id}
                    module={module}
                    reload={reload}
                />)
            } else if (contentType == "Task") {
                setContentDisplay(<EditTask
                    taskId={content.obj_id}
                    module={module}
                    reload={reload}
                />)
            }
        }
        setBuilderLoading(false)

    }

    const handleDeleteContent = async (e: any) => {
        e.preventDefault()
        console.log('content', content)
        const contentType = content.obj_type === "Quizes" ? "Quiz" : content.obj_type;
        const response = await DeleteModuleContent(contentType, content.obj_id)
        if (response.status) {
            api.success({
                message: response.message,
                duration: 2
            })
            reload()
            setContentDisplay(<EmptyState />)
        }

    }


    return (
        <>
            {contextHolder}
            <div className="title-box">
                {content.obj_type == "Lesson" ? <i className='fas fa-graduation-cap'></i> : ""}
                {content.obj_type == "Quiz" || content.obj_type == "Quizes" ? <i className='fas fa-question-circle'></i> : ""}
                {content.obj_type == "Task" ? <i className="fa-solid fa-clipboard-list"></i> : ""}
                {content.title}
            </div>
            <div className="option">

                <i className="fa-solid fa-pen" onClick={handleEditContent}></i>

                <Popconfirm
                    placement="topRight"
                    title={"Delete " + content.obj_type}
                    description={"Are you sure to delete " + content.title + " ?"}
                    onConfirm={(e) => handleDeleteContent(e)}
                    okText="Yes"
                    cancelText="No"
                >
                    <i className="fa-solid fa-trash" ></i>
                </Popconfirm>

            </div>
        </>
    )
}

