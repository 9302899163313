import React, { useEffect, useState } from 'react'
import EmptyState from './EmptyState'
import { Checkbox, Input, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getToken } from 'functions'
import MyEditor from 'components/ui/FroalaEditor'
import { Lesson, Module } from 'types';
import { DeleteLessonAttachment, GetLessonAttachment, UpdateLesson } from 'api';
import { HOST } from 'consts';



function EditLesson(props: {
    module: Module,
    lesson?: Lesson
    setContentDisplay: React.Dispatch<React.SetStateAction<JSX.Element>>,
    reload: () => void
}) {
    const { module, lesson: propsLesson, setContentDisplay, reload } = props;

    const [editName, setEditName] = useState(false)
    const [lesson, setLesson] = useState<Lesson>(null)
    const [api, contextHolder] = notification.useNotification()

    const onModelChange = (model: any) => {
        handleUpdateLesson({ name: "content", value: model })
    };

    const handleUpdateLesson = ({ name, value }: any) => {
        setLesson((prev: any) => ({
            ...prev,
            [name]: value
        }));
    };

    const updateLesson = async (method: string) => {
        if (!lesson) return
        console.log(lesson);

        const response = await UpdateLesson(lesson, lesson.id, method)

        if (response.status) {
            api.success({
                message: response.message,
                duration: 2
            })
            reload()
        }
    }

    useEffect(() => {
        if (propsLesson) {
            setLesson(propsLesson)
        }
    }, [propsLesson])

    const close = () => { setContentDisplay(<EmptyState />) }

    const handleEditLessonName = (arg: boolean) => {
        !arg && updateLesson("PUT")
        setEditName(arg)
    }


    return (
        <div className="edit-lesson">
            {contextHolder}
            {lesson &&
                <>
                    <div className={editName ? "header edit" : "header"} >
                        <div className="header-breadcrumb" onClick={() => handleEditLessonName(true)}>
                            <div className="title m-0">
                                {module.title}
                            </div>
                            <i className="las la-angle-right mx-2"></i>
                            <div className="title m-0">
                                {lesson.title}
                                <i className="fa-solid fa-pen ml-2"></i>
                            </div>
                        </div>
                        <div className="header-form" >
                            <Input style={{ width: '500px' }} value={lesson.title}
                                onChange={e => handleUpdateLesson({ name: "title", value: e.target.value })} />
                            <div className="button is-secondary is-outlined ml-3" onClick={() => handleEditLessonName(false)}>
                                <i className="fa-solid fa-check"></i>
                            </div>
                        </div>
                    </div>
                    <div className="form-box">
                        <div className="form lesson-form mt-5">
                            <div className="field">
                                <div className="control">
                                    <MyEditor
                                        content={lesson.content}
                                        onContentChange={onModelChange}
                                    />
                                </div>

                            </div>

                            <div className="field flex-group mt-5">
                                <div className="control">
                                    <div className="button is-secondary mr-2" onClick={() => updateLesson("PUT")}>Save</div>
                                    <div className="button" onClick={close}>Cancel</div>
                                </div>
                                {lesson &&
                                    <div className="button is-danger" onClick={() => updateLesson("DELETE")}>Delete</div>
                                }
                            </div>
                        </div>

                        <div className="panel-setting">
                            <div className="setting-box p-4">
                                <div className="header-box mb-4">
                                    <p className="title is-6">Setting</p>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <Checkbox
                                            checked={lesson.allow_preview}
                                            onChange={e => handleUpdateLesson({ name: "allow_preview", value: e.target.checked })}>
                                            Allow preview
                                        </Checkbox>
                                        <div className="tip">Allow user to see preview of this lesson.</div>
                                    </div>
                                </div>
                            </div>
                            <hr className='m-0' />
                            <div className="attachment-box p-4">
                                <div className="header-box mb-3">
                                    <p className="title is-6">Attachment</p>
                                </div>
                                <div style={{ width: "100%" }}>

                                    <LessonAttachment lesson={lesson} />
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }
        </div>

    )
}

export default EditLesson


function LessonAttachment(props: {
    lesson: Lesson
}) {
    const { lesson } = props;

    const [fileList, setFileList] = useState<any>([])

    const getAttachment = async () => {
        if (!lesson) return
        const response = await GetLessonAttachment(lesson.id)

        console.log(response)

        setFileList(() => response.files.map((item: any) => ({
            uid: item.id,
            name: item.attachment.split('/').pop(),
            status: 'done',
            url: item.url
        })));
    }

    const removeAttachment = async (uid: string) => {
        if (!lesson) return
        const response = await DeleteLessonAttachment(lesson.id, uid)
        console.log(response)
        return response.status
    }


    useEffect(() => {
        getAttachment()
    }, [])

    const handleChange = async (info: any) => {
        console.log(info)
        const { status, uid, name, response } = info.file;

        if (status === "uploading" && fileList.findIndex((file: any) => file.uid === uid) === -1) {
            setFileList((prev: any) => [
                ...prev,
                {
                    uid,
                    name,
                    status,
                    url: "",
                }
            ]);

        } else if (status === 'done') {
            // Handle server response
            console.log(info.file.response);

            // Update fileList with uploaded file
            setFileList((prev: any) => {
                const index = prev.findIndex((file: any) => file.uid === uid);
                if (index !== -1) {
                    prev[index] = {
                        uid,
                        name,
                        status: 'done',
                        url: response.url,
                    };
                }
                return [...prev];
            });
        } else if (status === 'removed') {
            // Handle file remove
            console.log('Removed file:', info.file);
            // Remove file from fileList
            const success = await removeAttachment(info.file.uid)
            if (success) {
                setFileList((prev: any) => prev.filter((file: any) => file.uid !== info.file.uid));
            }
        }

    }

    return (
        <Upload
            multiple
            fileList={fileList}
            headers={{ 'Authorization': `Bearer ${getToken()}` }}
            action={HOST + "/api/Course/e/Lesson/Attachment?pk=" + lesson.id}
            onChange={handleChange}
        // disable automatic upload
        >

            <Button style={{ width: "100%" }} icon={<UploadOutlined />}>Add Attachment</Button>

        </Upload>
    );
}