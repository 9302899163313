import { Checkbox, InputNumber } from 'antd';
import MyEditor from 'components/ui/FroalaEditor';
import React from 'react'

export default function TextQuestionForm(props: {
    question: any,
    updateQuestion: (obj: any) => void
}) {
    const { question, updateQuestion } = props

    const handleContent = (c: any) => {
        updateQuestion({ name: "question", value: c })
    }

    return (
        <div className="question-box" datatype="text">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    content={question.question}
                    onContentChange={handleContent}
                />
            </div>

            <hr />
            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Setting</div>
                </div>
                <div className="field">
                    <div className="control">
                        <Checkbox
                            onClick={(e: any) => updateQuestion(
                                {
                                    name: "limit_length",
                                    value: e.target.checked
                                }
                            )}
                            checked={question.limit_length}>
                            Limit length
                        </Checkbox>
                        <div className="tip">
                            Default limit length for user answer is 200 character,
                            enable this option to change the value.</div>
                    </div>
                </div>
                {
                    question.limit_length &&
                    <div className="field">
                        <div className="control">
                            <InputNumber
                                style={{ width: '120px' }}
                                min={1}
                                value={question.max_length}
                                onChange={(e) => updateQuestion({ name: "max_length", value: e })} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

